.buy-tickets-area-v2 .container {
  padding-top: 70px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
}

.buy-tickets .section-title h2.book-ticket-title-v2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 64px;
}

.buy-tickets-new-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  gap: 5px;
}

.buy-tickets-new-section-title h2 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
}

.buy-tickets-new-section-title div {
  width: fit-content;
  max-width: 140px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buy-tickets-new-section-title div p {
  color: white;
  font-weight: 200;
  font-size: 12px;
}

.lax-absolute {
  position: absolute;
  font-size: 48px;
  z-index: -1;
  font-weight: 700;
  color: #f4f4f4;
  right: 250px;
  text-transform: uppercase;
}

.blog-slides-wrapper-v2 div .owl-stage-outer {
  padding: 0px 1px;
  overflow: visible;
}

@media (min-width: 350px) {
  .buy-tickets-new-section-title div {
    max-width: 200px;
  }

  .buy-tickets-new-section-title div h2 {
    font-size: 28px;
  }
}

@media (min-width: 425px) {
  .buy-tickets-new-section-title div {
    max-width: 250px;
  }

  .buy-tickets-new-section-title div h2 {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .buy-tickets-area-v2 .container {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .buy-tickets-new-section-title div {
    max-width: 100%;
  }

  .buy-tickets-new-section-title div h2 {
    font-size: 40px;
  }

  .buy-tickets-new-section-title div p {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .buy-tickets-new-section-title div h2 {
    font-size: 64px;
  }

  .buy-tickets-new-section-title div p {
    font-size: 16px;
  }

  .buy-tickets-new-section-title {
    align-items: flex-end;
    margin-bottom: 50px;
  }

  .lax-absolute {
    font-size: 128px;
    top: 0px;
    right: 500px;
  }
}
@media (min-width: 1200px) {
  .buy-tickets-new-section-title h2 {
    font-size: 70px;
    max-width: 100%;
  }

  .buy-tickets-new-section-title {
    align-items: center;
  }
}
