.view-all-news-btn {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: white;
  padding: 4px 8px;
  background: black;
  border: 1px solid white;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  border-radius: 3px;
  display: inline-block;
  text-align: center;

  width: fit-content;
}

.view-all-news-btn * {
  color: white;
}

/* Hover effect */
.view-all-news-btn:hover {
  background: #333333; /* Slightly lighter than black */
  color: white;
}

.view-all-news-btn a:hover {
  color: inherit;
}

.view-all-news-btn a {
  display: flex;
  gap: 5px;
  align-items: center;
}

/* Tablet screens */
@media (min-width: 768px) {
  .view-all-news-btn {
    font-size: 16px;
    padding: 8px 12px;
  }
}

/* Desktop screens */
@media (min-width: 1024px) {
  .view-all-news-btn a {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .view-all-news-btn {
    font-size: 20px;
    padding: 12px 20px;
  }
}
