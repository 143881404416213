.event-countdown-timer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-countdown-timer #timer {
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.event-countdown-timer #timer div {
  display: flex;
  column-gap: 8px;
  font-size: 28px;
  font-weight: 900;
  color: #ffffff;
}

.event-countdown-timer #timer div#seconds {
  column-gap: 4px;
}

.event-countdown-timer #timer .event-countdown-timer-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-countdown-timer #timer div#days div {
  color: #f4595f;
  width: fit-content;
}

.event-countdown-timer #timer div#hours div {
  color: #00a5d6;
  width: fit-content;
}

.event-countdown-timer #timer div#minutes div {
  color: #ffffff;
  width: fit-content;
}

.event-countdown-timer #timer div#seconds div {
  color: #fbe305;
  width: 40px;
}

.event-countdown-timer #timer span {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.event-countdown-timer .event-countdown-timer-child span {
  font-size: 16px;
}

/* Tablet screens */
@media (min-width: 768px) {
}

/* Desktop screens */
@media (min-width: 1024px) {
  .event-countdown-timer #timer div {
    font-size: 64px;
  }

  .event-countdown-timer #timer {
    column-gap: 28px;
  }

  .event-countdown-timer #timer div#seconds div {
    text-align: center;
    width: 100px;
  }

  .event-countdown-timer #timer span {
    font-size: 20px;
  }
}
