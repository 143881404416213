.news-area-v2 .container {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.news-new-section-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 28px;
  gap: 5px;
}

.news-new-section-title h2 {
  font-size: 28px;
  font-weight: 400;
  max-width: 180px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.lax-absolute {
  position: absolute;
  font-size: 48px;
  z-index: -1;
  font-weight: 700;
  color: #f4f4f4;
  right: 250px;
  text-transform: uppercase;
}

.blog-slides-wrapper-v2 div .owl-stage-outer {
  padding: 0px 1px;
  overflow: visible;
}

@media (min-width: 350px) {
  .news-new-section-title h2 {
    font-size: 28px;
    max-width: 150px;
  }
}

@media (min-width: 425px) {
  .news-new-section-title h2 {
    font-size: 32px;
    max-width: 200px;
  }
}

@media (min-width: 768px) {
  .news-new-section-title h2 {
    font-size: 32px;
    max-width: 100%;
    font-size: 40px;
  }
}

@media (min-width: 1024px) {
  .news-area-v2 .container {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .news-new-section-title h2 {
    font-size: 70px;
    max-width: 600px;
  }

  .news-new-section-title {
    align-items: flex-end;
    margin-bottom: 50px;
  }

  .lax-absolute {
    font-size: 128px;
    top: 0px;
    right: 500px;
  }
}
@media (min-width: 1200px) {
  .news-new-section-title h2 {
    font-size: 70px;
    max-width: 100%;
  }

  .news-new-section-title {
    align-items: center;
  }
}
