/*================================================
Navbar CSS
=================================================*/
.elkevent-mobile-nav {
  display: none;
}

.elkevent-mobile-nav.mean-container .mean-nav ul li a.active {
  color: #98a464;
}

.elkevent-nav {
  background: #f5f5f5;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 0px;
  width: 100%;
  z-index: 999;
  height: auto;
}

.elkevent-nav nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.elkevent-nav nav .navbar-nav .nav-item {
  position: relative;
  padding: 15px 0;
}

.elkevent-nav nav .navbar-nav .nav-item a {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #1f3d19;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
}

.elkevent-nav nav .navbar-nav .nav-item .nav-link-main-page-scrolled {
  color: #1f3d19 !important;
}

.elkevent-nav nav .navbar-nav .nav-item a:hover,
.elkevent-nav nav .navbar-nav .nav-item a:focus,
.elkevent-nav nav .navbar-nav .nav-item a.active {
  color: #98a464;
}

.elkevent-nav nav .navbar-nav .nav-item:hover a {
  color: #98a464;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  background: #f5f5f5;
  position: absolute;
  top: 80px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li {
  position: relative;
  padding: 0;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 9px 15px;
  margin: 0;
  display: block;
  color: #1f3d19;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #98a464;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  position: absolute;
  left: 220px;
  top: 0;
  opacity: 0 !important;
  visibility: hidden !important;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #1f3d19;
  text-transform: capitalize;
}

.elkevent-nav
  nav
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.elkevent-nav
  nav
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.elkevent-nav
  nav
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #98a464;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1 !important;
  visibility: visible !important;
  top: -20px !important;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a {
  color: #98a464;
}

.elkevent-nav nav .navbar-nav .nav-item:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.elkevent-nav nav .others-option {
  margin-left: 13px;
}

.elkevent-nav.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background-color: #f5f5f5 !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.elkevent-nav #navbar-nav.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background-color: #f5f5f5 !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

/* Add CSS for better dropdown positioning */
.navbar-nav .nav-item {
  position: relative;
}

.navbar-nav .nav-item .dropdown-menu {
  left: 0;
  right: auto;
  margin-top: 0;
}

.navbar-nav .nav-item:last-child .dropdown-menu {
  left: auto;
  right: 0;
}

.nav-link {
  padding: 8px 15px;
  font-size: 15px;
  font-weight: 500;
  color: #2b2b2b;
  text-decoration: none;
  transition: all 0.5s;
}

.dropdown-menu {
  border: none;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  background: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 200px;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu li a,
.dropdown-menu li button {
  display: block;
  padding: 8px 15px;
  clear: both;
  font-weight: 400;
  color: #2b2b2b;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 15px;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus,
.dropdown-menu li button:hover,
.dropdown-menu li button:focus {
  color: #ff4f6b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.white-hamburger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.colored-hamburger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(31, 61, 25, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

@media only screen and (min-width: 991px) {
  .elkevent-nav nav .navbar-nav .nav-item .nav-link-main-page {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: white;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  }

  /* #navbar-nav div button span {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(31, 61, 25, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  } */
}

@media only screen and (max-width: 991px) {
  .navbar-nav .nav-item .dropdown-menu {
    position: static;
    float: none;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
  }

  .navbar-nav .nav-item:last-child .dropdown-menu {
    position: static;
    float: none;
  }

  .elkevent-nav nav .navbar-toggler {
    outline: 0;
    border: 0;
    /* color: #1f3d19; */
    color: transparent;
    /* border-color: #ffffff; */
  }
  /* .elkevent-nav nav .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(31, 61, 25, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  } */
  .elkevent-nav nav .collapse {
    background-color: #f5f5f5;
    padding: 15px 12px;
    margin-top: 5px;
    overflow-y: auto;
    max-height: 60vh;
  }
  .elkevent-nav nav .collapse::-webkit-scrollbar {
    width: 10px;
  }
  .elkevent-nav nav .collapse::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .elkevent-nav nav .collapse::-webkit-scrollbar-thumb {
    background: #888;
  }
  .elkevent-nav nav .collapse::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .elkevent-nav nav .navbar-nav .nav-item {
    padding: 8px 0;
  }
  .elkevent-nav nav .navbar-nav .nav-item a {
    font-size: 15px;
    margin: 0;
  }
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu {
    padding-top: 12px;
    padding-bottom: 12px;
    position: relative;
    opacity: 1;
    visibility: visible;
    top: 0 !important;
    width: 100%;
    margin-top: 10px;
  }
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 14px;
  }
  .elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    padding-top: 12px;
    padding-bottom: 12px;
    position: relative;
    opacity: 1 !important;
    visibility: visible !important;
    top: 0 !important;
    width: 90%;
    left: 15px;
    margin-top: 0;
    margin-bottom: 10px;
    background: #f5f5f5;
  }
  .elkevent-nav nav .others-option {
    margin-left: 0;
    margin-top: 10px;
  }
}
