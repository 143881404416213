.new-button {
  font-size: 14px;
  font-weight: 500;
  color: white;
  padding: 12px 32px;
  background: black;
  border: 1px solid white;
  text-decoration: none;
  transition: background 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.new-button * {
  color: white;
}

/* Hover effect */
.new-button:hover {
  transform: scale(1.05); /* Scales up slightly */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  color: white;
}

/* Ensures link color inherits hover styles */
.new-button a {
  color: inherit;
}

.new-button a:hover {
  color: inherit;
}

/* Tablet screens */
@media (min-width: 768px) {
  .new-button {
    font-size: 16px;
    padding: 14px 36px;
  }
}

/* Desktop screens */
@media (min-width: 1024px) {
  .new-button {
    font-size: 18px;
    padding: 16px 40px;
  }
}
