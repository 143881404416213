.home-about-layout {
  margin: 50px auto;
  max-width: 100%;
  width: 100%;
}

.home-about-description {
  margin: 50px auto;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.home-about-description * {
  margin: 0;
}

.home-about-description h1 {
  font-size: 16px;
  color: #ffc000;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}

.home-about-description h2 {
  font-weight: 300;
  font-size: 32px;
  text-transform: uppercase;
}

.home-about-description p {
  font-weight: 400;
  font-size: 14px;
}

.home-about-swiper-container {
  height: 200px;
  overflow: visible;
}

.home-about-swiper-container .swiper {
  width: 100%;
  height: 100%;
}

.home-about-swiper-container .home-about-swiper .swiper-slide {
  text-align: start;
  color: black;
  font-size: 18px;
  height: 100%;
  overflow: hidden;
  width: fit-content;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
}

.home-about-swiper-container .home-about-swiper .swiper-slide:first-child,
.home-about-swiper-container .home-about-swiper .swiper-slide:last-child {
  width: 2px;
  color: transparent;
  background: transparent;
  margin-left: 0px;
}

.home-about-swiper-container .home-about-swiper .swiper-slide img {
  display: block;
  width: 280px;
  height: 180px;
  object-fit: cover;
  border-radius: 16px;
}
.home-about-swiper-container .home-about-swiper .swiper-slide p {
  color: black;
  margin-left: 8px;
  font-weight: 300;
}

.gallery-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 0;
}

.gallery-layout h2 {
  font-weight: 300;
  font-size: 32px;
}

.gallery-layout a {
  position: relative; /* Ensure that the pseudo-element is positioned relative to the link */
  text-decoration: none; /* Remove the default underline */
  color: black !important; /* Keep the text color */
  font-size: 12px;
}

.gallery-layout a::after {
  content: ""; /* Empty content for the pseudo-element */
  position: absolute; /* Position it absolutely within the link */
  left: 0; /* Start from the left edge */
  bottom: 0; /* Position it at the bottom of the link */
  width: 100%; /* Full width of the link */
  height: 1px; /* Thickness of the underline */
  background: currentColor; /* Match the underline color with the text color */
  transform: scaleX(0); /* Initially scale it down to 0 (hidden) */
  transition: transform 0.3s ease; /* Smooth transition for the scale */
}

.gallery-layout a:hover::after {
  transform: scaleX(1); /* Scale the underline to full width on hover */
}

.home-yt-gallery {
  margin-top: 50px;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.home-yt-gallery .container iframe {
  height: 200px;
  width: 100%;
  border: white 2px solid;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
}

@media (min-width: 375px) {
  .home-yt-gallery {
    height: 300px;
  }

  .home-yt-gallery .container iframe {
    height: 250px;
  }
}

@media (min-width: 576px) {
  .home-about-swiper-container .home-about-swiper .swiper-slide:first-child,
  .home-about-swiper-container .home-about-swiper .swiper-slide:last-child {
    width: calc(((100vw - 516px) / 2) - 6px);
  }

  .home-yt-gallery {
    height: 500px;
  }

  .home-yt-gallery .container iframe {
    height: 400px;
  }
}

@media (min-width: 768px) {
  .home-about-swiper-container .home-about-swiper .swiper-slide:first-child,
  .home-about-swiper-container .home-about-swiper .swiper-slide:last-child {
    width: calc(((100vw - 696px) / 2) - 16px);
  }
}

@media (min-width: 992px) {
  .home-about-swiper-container .home-about-swiper .swiper-slide:first-child,
  .home-about-swiper-container .home-about-swiper .swiper-slide:last-child {
    width: calc(((100vw - 936px) / 2) - 26px);
  }
}

/* Desktop screens */
@media (min-width: 1024px) {
  .home-about-swiper-container {
    height: 420px;
    overflow: visible;
  }
  .home-about-swiper-container .home-about-swiper .swiper-slide {
    border-radius: 16px;
    width: 540px;
    height: 420px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }
  .home-about-swiper-container .home-about-swiper .swiper-slide img {
    display: block;
    width: 540px;
    height: 400px;
    /* height: 180px; */
    object-fit: cover;
    /* border-radius: 16px; */
  }
  .home-about-swiper-container .home-about-swiper .swiper-slide p {
    font-size: 20px;
  }

  .home-about-swiper-container .home-about-swiper .swiper-slide:first-child,
  .home-about-swiper-container .home-about-swiper .swiper-slide:last-child {
    width: calc(((100vw - 936px) / 2) - 26px);
  }

  .home-about-description h1 {
    font-size: 30px;
  }

  .home-about-description h2 {
    font-size: 70px;
  }

  .home-about-description p {
    font-weight: 400;
    font-size: 16px;
  }
  .home-about-description {
    row-gap: 20px;
  }

  .gallery-layout {
    margin-top: 75px;
  }

  .gallery-layout a {
    font-size: 16px;
  }

  .gallery-layout h2 {
    font-size: 70px;
    text-transform: uppercase;
  }

  .home-yt-gallery {
    margin-top: 100px;
    height: 1000px;
  }

  .home-yt-gallery .container iframe {
    height: 730px;
  }
}

@media (min-width: 1200px) {
  .home-about-swiper-container .home-about-swiper .swiper-slide:first-child,
  .home-about-swiper-container .home-about-swiper .swiper-slide:last-child {
    width: calc(((100vw - 1116px) / 2) - 26px);
  }
}

@media (min-width: 1400px) {
  .home-about-swiper-container .home-about-swiper .swiper-slide:first-child,
  .home-about-swiper-container .home-about-swiper .swiper-slide:last-child {
    width: calc(((100vw - 1296px) / 2) - 36px);
  }
}

.content-container {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.content-container.collapsed {
  max-height: 300px;
  overflow: hidden;
  position: relative;
}

.fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, transparent, white);
}

.read-more-button {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  color: #2563eb;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.read-more-button:hover {
  color: #1d4ed8;
}

.read-more-button svg {
  width: 16px;
  height: 16px;
  transition: transform 0.3s ease;
}

.read-more-button svg.rotated {
  transform: rotate(180deg);
}
