.sponsor-carousel-container {
  margin-bottom: 40px;
}

.sponsor-carousel-container h3 {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
}

.sponsor-carousel-border {
  height: 1px;
  width: 100%;
  background-color: black;
  margin: 14px 0px;
  border-radius: 5px;
}

.sponsor-carousel-image-card {
  background-color: #fafafa;
  padding: 8px;
  height: 100px;
  width: 170px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsor-carousel-image-card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (min-width: 768px) {
  .sponsor-carousel-container h3 {
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .sponsor-carousel-image-card {
    padding: 16px 24px;
    height: 200px;
    width: 360px;
  }
}
