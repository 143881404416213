.single-blog-post.single-blog-post-v2 {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.blog-post-content .date.date-v2 {
  color: black;
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
  margin: 0;
}

.blog-post-content h3.post-title-v2 a {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 90px;
  max-height: 90px;
}

.blog-post-content h3.post-title-v2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.single-blog-post .blog-post-content .post-content-v2 {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 350px;
  margin-bottom: 16px;
}

.single-blog-post .blog-post-content .post-content-v2 p {
  margin: 0;
}
