.place-date-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  gap: 12px;
  margin: 0;
  padding: 0;
}

/* Mobile styles */
.place-date-item {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}

@media (min-width: 340px) {
  .place-date-item {
    font-size: 14px;
  }
}

/* Tablet and larger screens */
@media (min-width: 768px) {
  .place-date-list {
    gap: 30px;
  }

  .place-date-item {
    font-size: 18px;
  }
}

/* Desktop and larger screens */
@media (min-width: 1024px) {
  .place-date-list {
    gap: 40px;
    display: flex;
    flex-direction: row;
  }

  .place-date-item {
    font-size: 20px;
  }
}
