/* ButtonStyles.css */
.animated-button {
  animation: shine 2s infinite;
}

@keyframes shine {
  0% {
    box-shadow: 0 0 3px #2ba805;
  }
  50% {
    box-shadow: 0 0 10px #49e819;
  }
  100% {
    box-shadow: 0 0 3px #2ba805;
  }
}

/* ButtonStyles.css */
.animated-button-dark {
  animation: shine-dark 2s infinite;
}

@keyframes shine-dark {
  0% {
    box-shadow: 0 0 3px #ffffff;
  }
  50% {
    box-shadow: 0 0 20px #ffffff;
  }
  100% {
    box-shadow: 0 0 3px #ffffff;
  }
}

/* ButtonStyles.css */
.animated-button-black {
  animation: shine-black 2s infinite;
}

@keyframes shine-black {
  0% {
    box-shadow: 0 0 3px #000000;
  }
  50% {
    box-shadow: 0 0 20px #000000;
  }
  100% {
    box-shadow: 0 0 3px #000000;
  }
}
