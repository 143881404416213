.blog-post-content h3.hotel-title-v2 a {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 60px;
  max-height: 60px;
}

.blog-post-content h3.hotel-title-v2 {
  margin-top: 0px;
  margin-bottom: 16px;
}

.single-blog-post .blog-post-content .hotel-content-v2 {
  margin: 0;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hotel-content-wrapper-v2 {
  display: flex;
  gap: 12px;
}
