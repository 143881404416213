.buy-tickets-btn {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  padding: 12px 8px;
  background: white;
  border: 1px solid white;
  text-decoration: none;
  transition: background 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}

.buy-tickets-btn * {
  color: black;
  text-transform: uppercase;
}

/* Hover effect */
.buy-tickets-btn:hover {
  color: black;
  transform: scale(1.05); /* Slightly scales up the button */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); /* Adds a soft shadow */
}

/* Ensures link color inherits hover styles */
.buy-tickets-btn a {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 10px;
  color: inherit; /* Ensures color consistency */
}

.buy-tickets-btn a:hover {
  color: inherit;
}

/* Tablet screens */
@media (min-width: 768px) {
  .buy-tickets-btn a {
    font-size: 16px;
  }
  .buy-tickets-btn {
    padding: 16px 24px;
  }
}

/* Desktop screens */
@media (min-width: 1024px) {
  .buy-tickets-btn a {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
