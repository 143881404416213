/* Footer.css */

.footer-area-v2 {
  background-color: #000; /* Black background */
  color: #fff; /* White text color */
  padding: 40px 0;
}

.footer-widget-v2 h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer-widget-v2 span,
.footer-widget-v2 p,
.footer-widget-v2 a {
  color: #fff;
  font-size: 14px;
}

.location-v2,
.contact-v2,
.contact-authority-v2 {
  display: block;
  color: #fff;
  margin-top: 10px;
  font-size: 14px;
}

.social-links-v2 {
  margin-top: 20px;
}

.social-links-v2 li {
  display: inline-block;
  margin-right: 10px;
}

.social-links-v2 a {
  color: #fff;
  font-size: 20px;
  transition: color 0.3s ease;
}

.social-links-v2 a:hover {
  color: #999; /* Light grey on hover */
}

.copyright-area-v2 {
  margin-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0px;
}

.copyright-area-v2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.copyright-area-v2 ul li a {
  color: #fff;
  font-size: 14px;
  transition: color 0.3s ease;
  text-decoration: none;
  padding-top: 4px;
}

.copyright-area-v2 p {
  color: #fff;
  font-size: 12px;
  margin-top: 10px;
}

@media (min-width: 1024px) {
  .copyright-area-v2 {
    flex-direction: row-reverse;
  }
}
