/* Default (Mobile) */
.responsive-icon {
  width: 20px;
  height: 20px;
}

/* Tablet screens */
@media (min-width: 768px) {
  .responsive-icon {
    width: 24px;
    height: 24px;
  }
}

/* Desktop screens */
@media (min-width: 1024px) {
  .responsive-icon {
    width: 28px;
    height: 28px;
  }
}
