.sponsor-card {
  margin-bottom: 40px;
}

div.gold-sponsor h3 {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.sponsor-card h3 {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
}

.sponsor-border {
  height: 1px;
  width: 100%;
  background-color: black;
  margin: 14px 0px;
  border-radius: 5px;
}

.sponsor-image-card {
  background-color: #fafafa;
  padding: 8px 16px;
  height: 100px;
  width: 170px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsor-image-card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

div.gold-sponsor .sponsor-image-card {
  height: 150px;
  width: 100%;
}

@media (min-width: 768px) {
  div.gold-sponsor h3 {
    font-size: 28px;
  }

  div.gold-sponsor .sponsor-image-card {
    height: 250px;
  }
  div.gold-sponsor .sponsor-image-card img {
    max-width: 750px;
  }
  .sponsor-image-card {
    padding: 16px 24px;
    height: 200px;
    width: 360px;
  }
}
